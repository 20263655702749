<template>
  <div id="audits-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/audits/add" class="btn btn-primary mb-5">Neuen Audit erstellen</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Make available a selection of sites to audit based on Customer Journey</li>
      <li>Build visual copy of those pages and be able to add notes in place</li>
      <li>Make background connection to Levels and Limbics</li>
      <li>Connect to <router-link to="/links">Link Tree</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/findings">Findings</router-link></li>
      <li>Connect to <router-link to="/ideas">Idea Backlog</router-link></li>
    </ul>-->

    <TheorySwitchHint v-if="audits && project.audits.length > audits.length" item="Audits"></TheorySwitchHint>

    <IntroBanner v-if="!(audits && audits.length)" link="/audits/add"></IntroBanner>

    <div v-for="(audit, index) in audits" class="card mb-5" :key="index">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-sm-4">
            <h4 class="my-0">{{ audit.title }}</h4>
          </div>
          <div class="col-sm-8">
            <router-link :to="'/audits/edit/' + audit.id" class="btn btn-primary float-right">
              Audit Details <i class="fad fa-arrow-right"></i>
            </router-link>
            <router-link v-if="audit.journey" :to="'/journeys/' + audit.journeyId"
                         class="btn btn-dark mr-3 float-right">
              "{{ audit.journey.title }}" Journey <i class="fad fa-fw fa-route"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <div v-if="activeTheoryId === ''">
              <h5>Verknüpfte Theorie:</h5>
              <p v-if="audit.theoryId">
                {{ project.theories.find(t => t.id === audit.theoryId).title }}
              </p>
              <p v-else>Keine Theorie verknüpft.</p>
              <hr>
            </div>
            <p>
              {{ audit.summary }}
            </p>
            <p class="small">{{ audit.createdAt }}</p>
            <ul>
              <li>{{ audit.steps.length }} Schritt{{ audit.steps.length === 1 ? '' : 'e' }}</li>
              <li>
                {{ audit.findings.length }} Finding{{ audit.findings.length === 1 ? '' : 's' }}
                <ul>
                  <li v-for="(finding, index) in audit.findings.slice(0,7)" :key="index">{{ finding.title }}</li>
                  <li v-if="audit.findings.length > 7">...</li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="col-sm-9">
            <NodeSelector :nodes="audit.steps.map(s => s.node)"
                          node-size="col-sm-9 col-md-6 col-lg-3"
                          wrapper-classes="scroll-container-h flex-nowrap"
                          :exclude-items="['audits']"
            ></NodeSelector>
          </div>
        </div>
      </div>
      <div class="remove-button" @click="remove(audit.id)">
        <i class="fas fa-times"></i>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import NodeSelector from "@/components/NodeSelector";
import IntroBanner from "@/components/IntroBanner";
import TheorySwitchHint from "@/components/TheorySwitchHint";

export default {
  name: 'Audits',
  components: {TheorySwitchHint, IntroBanner, NodeSelector},
  data() {
    return {
      selectedAudit: null,
    }
  },
  computed: {
    audits() {
      return this.project.audits
          .filter(audit => !this.activeTheoryId || audit.theoryId === this.activeTheoryId)
          .map(audit => {
        if (audit.journeyId) {
          audit.journey = this.project.journeys.find(journey => journey.id === audit.journeyId);
        }
        audit.steps = audit.steps.map(step => {
          step.node = this.findNode(step.nodeUrl);
          step.findings = this.project.findings.filter(f => f.auditId === audit.id && f.stepId === step.id);
          return step;
        });

        audit.findings = audit.steps.reduce((acc, step) => {
          return acc.concat(step.findings);
        }, []);

        return audit;
      });
    }
  },
  methods: {
    remove(id) {
      let audit = this.project.audits.find(j => j.id === id);
      if (confirm('Remove "' + audit.title + '" from Audits?')) {
        let index = this.project.audits.findIndex(j => j.id === id);
        this.project.audits.splice(index, 1);

        this.$store.dispatch('project/updateProjectByProp', {prop: 'audits', data: this.project.audits})
            .then(res => {
              console.debug(res);
            });
      }
    },
  },
  beforeMount() {
  }
}
</script>
